import { useContext } from "react";
import { BrazeContext } from "./provider";

export const useBraze = () => {
  const context = useContext(BrazeContext);
  if (context === undefined) {
    throw new Error("useBraze must be used within BrazeContext");
  }

  const { brazeClient } = context;

  return brazeClient;
};
