import React from "react";

const CommonButton = ({
  label = "Button",
  fgColor = "white",
  bgColor = "primary",
  font = "gExtBlack",
  onClick,
  enabled = true,
  type = "button",
  dataTestId = "button",
  className,
}) => {
  return (
    <button
      className={`
			w-[144px] h-[48] xs:h-[36px] rounded flex items-center justify-center uppercase font-${font}
			bg-${bgColor}
			${enabled ? "" : "disabled:opacity-20"}
			hover:bg-secondary hover:text-white disabled:opacity-30 p-[6px]
			${className}`}
      type={type}
      onClick={enabled ? onClick : undefined}
      data-testid={dataTestId}
      disabled={!enabled}
    >
      {label}
    </button>
  );
};

export default CommonButton;
