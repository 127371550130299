import React from "react";
import CommonButton from "@/components/common/buttons/CommonButton";

const ButtonGroup = ({
  primaryLabel = "Primary",
  primaryAction,
  primaryEnabled = true,
  primaryType = "button",
  primaryDataTestId,
  secondaryLabel = "Secondary",
  secondaryAction,
  secondaryEnabled = true,
  secondaryType = "button",
  secondaryDataTestId,
  tertiaryLabel = "Quit",
  tertiaryAction,
  tertiaryEnabled = true,
  tertiaryType = "button",
  tertiaryDataTestId,
  className,
  style = "default"
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-between max-w-[650px] w-11/12 gap-4 ${className}`}
    >
      <div
        className={`flex w-full ${
          !!(primaryAction && secondaryAction) ? "flex-row" : "flex-col"
        }  items-center justify-center gap-4`}
      >
        {!!secondaryAction && (
          <CommonButton
            label={secondaryLabel}
            onClick={secondaryAction}
            bgColor={style === 'live' ? "arts" : "white"}
            fgColor={"primary"}
            className={style === 'live' ? "grow" :"bg-opacity-20 text-white grow"}
            type={secondaryType}
            enabled={secondaryEnabled}
            dataTestId={secondaryDataTestId || secondaryLabel}
          />
        )}
        {!!primaryAction && (
          <CommonButton
            label={primaryLabel}
            onClick={primaryAction}
            bgColor={style === 'live' ? "arts" : "primary"}
            fgColor={"white"}
            className={"grow"}
            type={primaryType}
            enabled={primaryEnabled}
            dataTestId={primaryDataTestId || primaryLabel}
          />
        )}
      </div>
      {!!tertiaryAction && (
        <CommonButton
          label={tertiaryLabel}
          onClick={tertiaryAction}
          bgColor={"primary"}
          fgColor={"white"}
          type={tertiaryType}
          enabled={tertiaryEnabled}
          className={"w-full px-4"}
          dataTestId={tertiaryDataTestId || tertiaryLabel}
        />
      )}
    </div>
  );
};

export default ButtonGroup;
