import React, { useEffect, useState } from "react";
import { STORAGE_KEY } from "@/lib/utils";
import toast from "react-hot-toast";
import LargeTpiLogo from "../logos/LargeTpiLogo";
import ButtonGroup from "../common/buttons/ButtonGroup";
import { useRouter } from "next/router";
import { useBraze } from ".";
import sha256 from "crypto-js/sha256";

export const BrazeEmailPrompt = ({ onSubscribe }) => {
  const brazeClient = useBraze();
  const [uid, setUid] = useState(null);
  const [optingIn, setOptingIn] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [shake, setShake] = useState(false);
  const router = useRouter();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const brazeUser = brazeClient.getUser();

    if (optingIn) {
      const isEmailValid = brazeUser?.setEmail(email);
      if (!isEmailValid) {
        setInvalidEmail(true);
        return (
          !shake &&
          setShake(
            setTimeout(() => {
              setShake(false);
            }, 500)
          )
        );
      }
      setInvalidEmail(false);
      brazeUser?.setEmailNotificationSubscriptionType("opted_in");

      // add hashed email as external_id
      brazeClient.changeUser(sha256(email).toString());
    }

    brazeUser?.addAlias(uid, "uid");

    // const isAccessEnabled = await enableEndlessMode(uid);
    // if (isAccessEnabled) {
    return onSubscribe();
    // }
  };

  /* This is no longer a requirement for playing infinite mode */
  /** Enables endless mode for a user */
  // const enableEndlessMode = async (uid) => {
  //   const res = await fetch(`/api/user/${uid}/braze/subscribe`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   if (res.ok) {
  //     toast.success(
  //       optingIn
  //         ? "Subscribed to receive e-mail communications"
  //         : "Email submitted successfully. Have fun playing!"
  //     );
  //     return true;
  //   }
  //   toast.error("Something went wrong while subscribing for e-mail communications");
  //   return false;
  // };

  useEffect(() => {
    const localUid = localStorage.getItem(STORAGE_KEY);
    setUid(localUid);
  }, []);

  return (
    <form
      className={
        "flex flex-col flex-grow items-center w-[85%] max-w-[1040px] min-w-[360px] md:p-8 p-4 md:gap-6 gap-4"
      }
      data-testid="braze-email-prompt"
      onSubmit={handleSubmit}
    >
      <div
        className={
          "bg-white text-primary lg:text-xl md:text-lg text-sm rounded-lg bg-opacity-[85%] p-8"
        }
      >
        <h1 className={"md:text-3xl text-2xl md:font-gBold font-gSemi"}>
          Please enter your email address
        </h1>
        <div className={"md:text-lg text-sm flex gap-2 pb-2 my-4"}>
          <input
            id="link-checkbox"
            data-testid="link-checkbox"
            type="checkbox"
            className="w-4 h-4 mt-1 md:mt-1.5 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-1 dark:bg-gray-700 dark:border-gray-600"
            checked={optingIn}
            onChange={() => setOptingIn((prev) => !prev)}
          />
          <label htmlFor="link-checkbox" className={"font-gReg text-base"}>
            {
              "I'm opting in! I want to be among the first to find out about Trivial Pursuit Infinite updates by receiving e-mail communications."
            }
          </label>
        </div>
        <div
          className={`py-2 px-3 rounded-lg border-solid border-2 ${
            !shake && !invalidEmail ? "border-transparent" : "border-danger"
          } focus-within:border-violet-600 bg-white focus-within:bg-opacity-100 ${
            shake ? "animate-shake" : ""
          }`}
        >
          <input
            name="email"
            placeholder="Enter your email"
            className="w-full bg-transparent placeholder:opacity-50 focus:outline-none"
            type="email"
            id="email"
            data-testid="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <ButtonGroup
        primaryLabel={"Submit"}
        primaryEnabled={email}
        primaryAction={() => null}
        primaryType="submit"
        secondaryLabel={'Cancel'}
        secondaryAction={() => router.push("/")}
      />
    </form>
  );
};
