import { useState } from 'react';
import { BrazeProvider } from "@/components/braze";
import Script from "next/script";
import { getPublicConfig } from "./api/config";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import "@/styles/globals.css";

export default function App({ Component, pageProps }) {
  const [queryClient] = useState(() => new QueryClient())

  return (<>
    <Script id="ketch">
      {`!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has("property")?e.get("property"):"website_smart_tag",n=document.createElement("script");n.type="text/javascript",n.src="https://global.ketchcdn.com/web/v3/config/hasbro/".concat(o,"/boot.js"),n.defer=n.async=!0,document.getElementsByTagName("head")[0].appendChild(n)}();`}
    </Script>
    <Script
      async
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7520484429350419"
      strategy="afterInteractive"
      crossOrigin="anonymous"
      onError={(e) => {
        console.error("Script failed to load", e);
      }}
    />
    
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={pageProps.dehydratedState}>
        <BrazeProvider
          apiKey={getPublicConfig("brazeSdkKey")}
          enableLogging={getPublicConfig("environment") === "development"}
          baseUrl="sdk.iad-03.braze.com"
        >
          <Component {...pageProps} />
        </BrazeProvider>
      </HydrationBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </>)
}
