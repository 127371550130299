import {
	BUY_NOW_URL,
	ENDLESS_PROMPT_MAX,
	ENDLESS_PROMPT_MIN,
	ENDLESS_PROMPT_RESTRICTED_CHARS,
	GAME_MODES,
	STANDARD_CATEGORIES
} from "@/lib/constants";
import banListInbound from '../res/ban-list-inbound.json' assert {type: 'json'};
import banListOutbound from '../res/ban-list-outbound.json' assert {type: 'json'};
import optimizelySdk from '@optimizely/optimizely-sdk';
import jwt from 'jsonwebtoken';
import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import { LOADING_MESSAGES } from "./constants.mjs";
import { getPublicConfig } from '@/pages/api/config';

export const STORAGE_KEY = "TrivialPursuitInfinite";
const DEFAULT_JWT_SECRET = "abcdefghijklmnopqrstuvwxyz0123456789";

export const getUser = async (gameMode, shareId = null) => {
	try {
		let api = `/api/user`;

		// check for stored uid
		let uid = localStorage.getItem(STORAGE_KEY);
		// console.log('uid:', uid)

		if (!uid) {
			const res = await fetch(api);
			const player = await res.json();
			uid = player.uid;
			localStorage.setItem(STORAGE_KEY, player.uid);
		}

		if (uid) {
			api += `/${uid}`;
			switch (gameMode) {
				case GAME_MODES.daily:
					api += '/daily'
					break;
				case GAME_MODES.endless:
					api += '/infinite'
					break;
				case GAME_MODES.playlist:
					api += `/playlist?shareId=${shareId}`
					break;
				default:
					break;
			}
		}
		const res = await fetch(api);

		// if stored uid is not found...
		if (uid && res.status === 404) {
			// get rid of it and try again
			localStorage.removeItem(STORAGE_KEY);
			return getUser();
		}

		const state = await res.json();
		return state;
	} catch (e) {}
};

export const mapPlaylistItems = (list) => {
	const items = list.map((tpl, i) => { 
		return { 
			_id: tpl._id, 
			shareId: tpl.shareId, 
			topic: tpl.playlist.topic, 
			rank: i+1, played: 
			tpl.played 
		}
	})
	return items
}

export const isEndlessInputValid = (prompt) => {
	if (prompt && prompt.length >= ENDLESS_PROMPT_MIN && prompt.length <= ENDLESS_PROMPT_MAX) {
		return !ENDLESS_PROMPT_RESTRICTED_CHARS.test(prompt)
	}
	return false;
}

const buildRegex = (list) => {
	const escapedWords = list.map(escapeRegExp);
	return new RegExp(`\\b(${escapedWords.join("|")})\\b`, "ig");
};

let _inboundRegex;
const getInboundRegex = () => {
	if (!_inboundRegex) {
		_inboundRegex = buildRegex(banListInbound);
	}
	return _inboundRegex;
}

let _outboundRegex;
const getOutboundRegex = () => {
	if (!_outboundRegex) {
		_outboundRegex = buildRegex(banListOutbound);
	}
	return _outboundRegex;
}

export const containsProfanityInbound = (str) => {
	const ALLOW_PROFANITY = isTruthy(process.env.ALLOW_PROFANITY);
	let regex = getInboundRegex()
	if (ALLOW_PROFANITY) return false
	regex.lastIndex = 0
	if (regex.test(str)) {
		console.log('inbound profanity match:', JSON.stringify(str.match(regex)))
	}
	return regex.test(str)
};

export const containsProfanityOutbound = (str) => {
	const ALLOW_PROFANITY = isTruthy(process.env.ALLOW_PROFANITY);
	let regex = getOutboundRegex()
	if (ALLOW_PROFANITY) return false
	regex.lastIndex = 0
	if (regex.test(str)) {
		console.log('outbound profanity match:', JSON.stringify(str.match(regex)))
	}
	return regex.test(str)
};

export const escapeRegExp = (text) => {
	return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const isTruthy = (value) => {
	if (value) {
		if (typeof value === 'string') {
			let lower = value.toLowerCase()
			switch (lower) {
				case 'true':
				case 'y':
				case 'yes':
				case '1':
				case 'on':
					return true
				default:
					return false
			}
		}
		if (typeof value === 'number') {
			return value > 0
		}
		return !!value
	}
	return false
}

export const clearPursuit = (pursuit, state) => {
	pursuit.question = null,
	pursuit.choices = null,
	pursuit.answer = null,
	pursuit.userChoice = null,
	pursuit.state = state;
	pursuit.code = null;
	return pursuit;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendGtagEvent = ({ action, category, label, value, user_id, ...rest }) => {
	if (window?.dataLayer) {
		window.dataLayer.push({
			event: action,
			category: category,
			label: label,
			value: value,
			user_id: user_id || undefined,
			version: getPublicConfig('version'),
			...rest
		});
	}
};

export const historyToPieMask = (questions, playerChoices) => questions?.reduce((a, c, i) => {
	if (c.answer === playerChoices[i]) {
		const {mask} = STANDARD_CATEGORIES.find(cat => cat.index === i)
		a += mask;
	}
	return a;
}, 0) || 0

export const dailyToPieMask = (wedges) => STANDARD_CATEGORIES.reduce((a, c) => {
	if (wedges && wedges[c.key]?.today) {
		a += c.mask
	}
	return a;
}, 0) || 0

/** Signs and returns a JWT with specified payload and expiry time */
export const signJWT = async (payload, expiresIn) => {
  return new Promise((resolve, reject) => {
    jwt.sign(
      payload,
      process.env.JWT_SECRET_KEY || DEFAULT_JWT_SECRET,
      {
        expiresIn,
      },
      (err, token) => {
        err ? reject(err) : resolve(token);
      }
    );
  });
};

/** Verifies JWT and returns the payload, if JWT is valid! */
export const verifyJWT = async (token) => {
  return new Promise((resolve, reject) => {
    jwt.verify(token, process.env.JWT_SECRET_KEY || DEFAULT_JWT_SECRET, {}, (err, token) => {
      err ? reject(err) : resolve(token);
    });
  });
};

export const getChoiceColor = (bgColor, thisChoice, userChoice, answer, isTimerRunning) => {
	if (answer) {
		if (userChoice === thisChoice && answer === thisChoice) return 'bg-success';
		if (userChoice === thisChoice && answer !== thisChoice) return 'bg-danger opacity-75';
		if ((userChoice === '' || !userChoice) && answer === thisChoice) return 'bg-danger';
		if (answer === thisChoice && userChoice !== thisChoice) return 'bg-success';
	}
	if (userChoice && userChoice !== thisChoice) return bgColor + ' opacity-30';
	return isTimerRunning || userChoice === thisChoice? bgColor : bgColor + ' opacity-30';
}

export const getUtmProductUrl = (utmContent) => (
	`${BUY_NOW_URL}&utm_content=${utmContent}`
)

export const getRandomLoadingMsg = () => LOADING_MESSAGES[Math.floor(Math.random() * Object.keys(LOADING_MESSAGES).length)];

export const cn = (...inputs) => twMerge(classnames(inputs))
