import version from '../../package.json'; // Really package, but cannot name it package

const config = {
   // Will be available on both server and client
    gaTrackingId: process.env.GA_TRACKING_ID || '',
    ddEnabled: process.env.DD_ENABLED || false,
    ddApplicationId: process.env.DD_APPLICATION_ID || '',
    ddClientToken: process.env.DD_CLIENT_TOKEN || '',
    ddSampleRate: process.env.DD_SAMPLE_RATE || 100,
    ddSessionReplaySampleRate: process.env.DD_REPLAY_SAMPLE_RATE || 20,
    environment: process.env.ENVIRONMENT || 'development',
    captchaSiteKey: process.env.CAPTCHA_SITE_KEY || '',
    brazeSdkKey: process.env.BRAZE_SDK_KEY || '',
    infiniteEnabled: process.env.INFINITE_ENABLED || false,
    captchaEnabled: process.env.CAPTCHA_ENABLED || true,
    version: version.version,
    TPI_LIVE_SERVER: process.env.TPI_LIVE_SERVER || 'http://localhost:5000',
    PLAYER_UI_URL: process.env.PLAYER_UI_URL || 'https://trivialpursuit.com',
}

// properly access public runtime configuration on both client-side and server-side
export const getPublicConfig = (name) =>
  typeof window === 'undefined' ? config[name] : window.PUBLIC_CONFIG[name];

export default function handler(_req, res) {
  res.status(200).send(`window.PUBLIC_CONFIG = ${JSON.stringify(config)}`);
}