import { useState, useEffect, createContext } from "react";

export const BrazeContext = createContext(undefined);

export function BrazeProvider({ children, apiKey, ...config }) {
  const [brazeClient, setBrazeClient] = useState(null);

  async function importBraze() {
    const braze = await import("@braze/web-sdk");
    setBrazeClient(braze);
  }

  useEffect(() => {
    importBraze();
  }, []);

  useEffect(() => {
    if (!brazeClient) return;

    brazeClient.initialize(apiKey, config);
  }, [brazeClient, apiKey, config]);

  return <BrazeContext.Provider value={{ brazeClient }}>{children}</BrazeContext.Provider>;
}
