import React from 'react';
import TpiHorizontal from "@/components/logos/TpiHorizontal";
import {useRouter} from "next/router";

const LargeTpiLogo = () => {
	const router = useRouter();
	return (
		<div data-testid="large-tpi-logo" className={'w-1/2 min-w-[340px] max-w-[960px] h-fit max-h-1/3 cursor-pointer'} onClick={() => router.push('/')}>
			<TpiHorizontal width={'100%'} height={'100%'} />
		</div>
	);
};

export default LargeTpiLogo;
