import { DAILY_GAME_STATES } from "@hasbro/tpi-data-models";

export const TOAST_DURATION = 3000; // 3 seconds
export const FEEDBACK_FORM_URL = 'https://survey.alchemer.com/s3/7629882/Trivial-Pursuit-Infinite-Bug-Report'
export const BUY_NOW_URL = 'https://www.amazon.com/stores/page/166FD13B-F094-4F4C-AE18-653B003B7739?ingress=0&visitId=96ffc048-5330-4a77-a978-abd14689ad3c&utm_source=tpi_game'

export const RUNS_PER_DAY = 3;
export const QUESTIONS_PER_RUN = 6;

export const SUPPORTED_LANGUAGE_MODELS = [
	// 'gpt-3.5-turbo-16k',
	'gpt-4',
]
export const DEFAULT_LANGUAGE_MODEL = SUPPORTED_LANGUAGE_MODELS[0];

export const MIN_PROMPT_MEMORY = 1;
export const MAX_PROMPT_MEMORY = 10;
export const DEFAULT_PROMPT_MEMORY = 4;

export const MIN_PROMPT_BUFFER = 1;
export const MAX_PROMPT_BUFFER = 10;
export const DEFAULT_PROMPT_BUFFER = 2;

export const DAILY_TIMER_LIMIT = 30000; // milliseconds
export const ENDLESS_TIMER_LIMIT = 30000; // milliseconds
export const MIN_TIMER_LIMIT = 0;
export const MAX_TIMER_LIMIT = 90000; // 90 seconds
export const DEFAULT_USER_SETTINGS = Object.freeze({
	model: DEFAULT_LANGUAGE_MODEL,
	memory: DEFAULT_PROMPT_MEMORY,
	buffer: DEFAULT_PROMPT_BUFFER,
	dailyTimerLimit: DAILY_TIMER_LIMIT,
	endlessTimerLimit: ENDLESS_TIMER_LIMIT,
});

export const GAME_MODES = Object.freeze({
	daily: 'daily',
	endless: 'endless',
	playlist: 'playlist',
	live: 'live',
})

export const DAILY_USER_ACTIONS = Object.freeze({
	start: 'start',
	choice: 'choice',
	next: 'next',
})

export const ENDLESS_USER_ACTIONS = Object.freeze({
	start: 'start',
	prompt: 'prompt',
	play_share: 'play_share',
	choice: 'choice',
	next: 'next',
	reset: 'reset',
	play_again: 'play_again',
	quit: 'quit',
})

export const ENDLESS_PROMPT_MIN = 4;
export const ENDLESS_PROMPT_MAX = 45;
export const ENDLESS_PROMPT_RESTRICTED_CHARS = /(<>)/;

export const DIFFICULTY_COLORS = {
	Easy: {selected: 'bg-easy text-white', normal: 'bg-white text-easy'},
	Medium: {selected: 'bg-medium text-white', normal: 'bg-white text-medium'},
	Hard: {selected: 'bg-hard text-white', normal: 'bg-white text-hard'},
}

export const MASK_ENTERTAINMENT = 0x01;
export const MASK_GEOGRAPHY = 0x02;
export const MASK_SPORTS = 0x04;
export const MASK_SCIENCE = 0x08;
export const MASK_HISTORY = 0x10;
export const MASK_ARTS = 0x20;

export const STANDARD_CATEGORIES = [
	Object.freeze({
		index: 0,
		label: "Entertainment",
		key: "entertainment",
		color: '#C44683',
		mask: MASK_ENTERTAINMENT,
		emoji: "🎭",
	}),
	Object.freeze({
		index: 1,
		label: "Geography",
		key: "geography",
		color: '#3A98CB',
		mask: MASK_GEOGRAPHY,
		emoji: "🌎",
	}),
	Object.freeze({
		index: 2,
		label: "Sports & Leisure",
		key: "sports",
		color: '#EC6C35',
		mask: MASK_SPORTS,
		emoji: "🏈",
	}),
	Object.freeze({
		index: 3,
		label: "Science & Nature",
		key: "science",
		color: '#8DC640',
		mask: MASK_SCIENCE,
		emoji: "⚛️",
	}),
	Object.freeze({
		index: 4,
		label: "History",
		key: "history",
		color: '#FEE62A',
		mask: MASK_HISTORY,
		emoji: "🦖",
	}),
	Object.freeze({
		index: 5,
		label: "Arts & Literature",
		key: "arts",
		color: '#9063B9',
		mask: MASK_ARTS,
		emoji: "🎨",
	}),
];

export const STANDARD_CATEGORIES_BY_KEY = STANDARD_CATEGORIES.reduce((a, c) => {
	a[c.key] = c;
	return a;
}, {})

export const DEFAULT_DAILY = {
	state: DAILY_GAME_STATES.Ready,
	wedges: {
		geography: {
			played: 0,
			collected: 0,
			today: 0,
			average: 0,
		},
		entertainment: {
			played: 0,
			collected: 0,
			today: 0,
			average: 0,
		},
		history: {
			played: 0,
			collected: 0,
			today: 0,
			average: 0,
		},
		arts: {
			played: 0,
			collected: 0,
			today: 0,
			average: 0,
		},
		science: {
			played: 0,
			collected: 0,
			today: 0,
			average: 0,
		},
		sports: {
			played: 0,
			collected: 0,
			today: 0,
			average: 0,
		},
	},
	collectedToday: 0,
	totalPlayed: 0,
	totalCollected: 0,
	average: 0,
	currentStreak: 0,
	maxStreak: 0,
	lastAction: null,
}

export const LOADING_MESSAGES = [
	"Cooking up fresh trivia... Stay tuned!",
	"Trivia brewing... almost ready to serve!",
	"Assembling brain teasers... Hold tight!",
	"Gathering fun facts... Hang on!",
	"Concocting your trivia challenge... Almost there!",
	"Sifting through trivia treasure... Patience, please!",
	"Whipping up knowledge nuggets... Just a sec!",
	"Mixing a trivia cocktail... Stay thirsty!",
	"Trivia wizards at work... Magic in progress!",
	"Polishing quiz questions... Shine takes time!",
]

export const exportConstants = {
	STANDARD_CATEGORIES,
	STANDARD_CATEGORIES_BY_KEY,
	DAILY_GAME_STATES,
	DAILY_USER_ACTIONS,
	DEFAULT_DAILY,
}

export const PLAYLIST_DETAILS = {
	trending : {
		label: "Trending Playlists",
		key: "trending",
		color: '#C4141B',
	},
	staff_pick:{
		label: "Staff Picks",
		key: "staff_pick",
		color: '#50197D',
	},
	endless:{
		label: "Infinite Pursuit",
		key: "endless",
		color: '#1D2142',
	},
}

export default exportConstants